.type {
    width: 100%;
    /* margin: 15px; */
}
.type input {
    font-size: 15px;
    font-weight: 400;
    box-sizing: border-box;
    border: 1px solid #dfeaf2;
    border-radius: 15px;
    margin-top: 12px;
}
.type input:hover {
    background: #fbfbfb;
    /* border: 1px solid red; */
}
.type input:focus-visible {
    outline: none;
    background: #fbfbfb;
    border: 1px solid #2d60ff;
}
.type select:focus-visible {
    outline: none;
    background: #fbfbfb;
    border: 1px solid #2d60ff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
