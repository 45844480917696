:root {
    --primary-background:#f5f7fa;
    --secondary-background:#EDF5FE;
    --tertiary-background:#eff3ff;
    --primary-color:#522e92;
    --secondary-color:#000;
    --tertiary-color:#9d9d9d;
    --title: 18px;
    --sub-title: 16px;
    --font-family: "Inter" ;
    --primary-gradient: linear-gradient(180.07deg, #552e91 42.38%, #5f2c8e 68.6%, #ed1561 154.26%);
    --button-title-color:#fff;
    --button-background:#917afd;
    --link-color:#8561c6;
    --active-button-background:#f1f5ee;
    --active-button-color:#79a358;
    --primary-font-weight: 700;
    --secondary-font-weight:600;
    --tertiary-font-weight:400;
  }
