.body {
	display: flex;
	min-height: 100vh;
	position: relative;
}
.body-container {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	margin-left: 330px;
}
.select-input {
	background: #f9f9f9;
	border: none;
	text-align-last: center;
	padding: 15px 0;
	font-size: 18px;
	font-weight: 400;
	color: #522e92;
	font-family: "Inter";
	width: 160px;
	border-radius: 10px;
	margin-left: 20px;
	background: url("../../assets/images/down-arrow.svg") no-repeat right
		#f9f9f9;
	-webkit-appearance: none;
	background-position-x: 127px;
}
.select-input:focus-visible {
	outline: none;
}

.number {
	background: #fff;
	display: flex;
	padding: 10px;
	align-items: center;
	border-radius: 10px;
}

.number div {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}
.number div img {
	cursor: pointer;
}
.number div img.decrement {
	transform: rotate(180deg);
}
.totalLeads .chooseLeads {
	background: #f9f9f9;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}
.totalLeads .active {
	background: #522e92;
	padding: 10px 20px;
	border-radius: 5px;
}
.totalLeads .active h3 {
	color: #fff;
}
.totalLeads .select {
	width: 100%;
	border: none;
	padding: 20px;
	background: url("../../assets/images/down-arrow.svg") no-repeat right
		#f9f9f9;
	-webkit-appearance: none;
	background-position-x: 500px;
}
.totalLeads .select-input {
	padding-right: 28px;
	margin: 0;
}
.assigned .active {
	background: #fff;
	display: flex;
	align-items: center;
	height: 32px;
	padding: 0 20px;
	
	border-radius: 10px;
}
.assigned .active h3 {
	color: #7B6294;
	font-weight: 500;
}
.assigned .menu-status {
	background: #F1F8FE;
	display: flex;
	align-items: center;
	border: 1px solid #CCE7FF;
	height: 32px;
	padding: 0 20px;
	border-radius: 10px !important;
}
.assigned .menu-status h3 {
	color: #552e91;
	font-weight: 500;
}
@media only screen and (max-width: 1500px) {
	.body-container {
		margin-left: 60px;
	}
}
