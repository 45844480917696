.body {
    display: flex;
    min-height: 100vh;
    position: relative;
}
.body-container {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    margin-left: 300px;
}
.select-input {
    border: none;
    text-align-last: center;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 400;
    color: #522e92;
    font-family: "Inter";
    width: 160px;
    border-radius: 10px;
    margin-left: 20px;
    background: url("../../assets/images/down-arrow.svg") no-repeat right
        #f9f9f9;
    -webkit-appearance: none;
    background-position-x: 127px;
}
.select-input:focus-visible {
    outline: none;
}
.leads .number {
    background: #fff;
    display: flex;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
}

.leads .number div {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.leads .number div img {
    cursor: pointer;
}
.leads .number div img.decrement {
    transform: rotate(180deg);
}
@media only screen and (max-width: 1500px) {
    .body-container {
        margin-left: 60px;
    }
}
