.navBar {
    background: linear-gradient(
        180.07deg,
        #552e91 42.38%,
        #5f2c8e 68.6%,
        #ed1561 154.26%
    );
    width: 330px;
    box-sizing: border-box;
    padding: 50px 20px;
    position: fixed;
    height: 100vh;
    overflow: scroll;
    z-index: 200;
    /* height: 100; */
}
.navBar .top {
    width: 75%;
    margin-bottom: 30px;
    margin-left: 15px;
}
.navBar .top img {
    width: 100%;
    height: 100%;
}
.navBar .bottom ul li {
    margin-bottom: 20px;
}
.navBar .bottom ul li div.left {
    display: flex;
    align-items: center;
}
.navBar .bottom ul li div.right {
    width: 16px;
    height: 16px;
}
.navBar .bottom ul li div.right img {
    width: 100%;
    height: 100%;
    display: block;
}
.navBar .bottom ul li div.left img {
    width: 24px;
    height: 24px;
    display: block;
    margin-right: 10px;
}
.navBar .bottom ul li .nav {
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 15px;
    color: #fff;
    opacity: 0.4;
    cursor: pointer;
    justify-content: space-between;
    flex-wrap: wrap;
}
.navBar .bottom ul li .nav:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}
.navBar .bottom ul li h3 {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}
.navBar .bottom ul li#active .nav {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    opacity: 1;
}
.navBar .bottom ul li.inner-active .nav {
    color: #fff;
    opacity: 1;
}
.navBar .bottom ul li.inner-active .nav .right img {
    transform: rotate(90deg);
}
.navBar .bottom ul li#active .nav h3 {
    font-weight: 500;
    color: #fff;
    font-size: 16px;
}
.navBar .bottom ul li div.container {
    text-align: left;
    padding: 10px 0 10px 35px;
    box-sizing: border-box;
}
.navBar .bottom ul li div.container span {
    border: 0.5px solid;
    border-color: rgba(255, 255, 255, 0.25);
    margin-right: 20px;
}
.navBar .bottom ul li.open ul {
    display: block;
}
.navBar .bottom ul li div.container a.innerItemLink {
    display: inline-block;
    width: 100%;
}
.navBar .bottom ul li div.container div.innerItem {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    padding-right: 0;
    color: #fff;
    opacity: 0.4;
    cursor: pointer;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
}
.navBar .bottom ul li div.container div.innerItem:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.navBar .bottom ul li div.container div#active {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    opacity: 1;
}
@media all and (max-width: 1500px) {
    .navBar {
        width: 60px;
        padding: 0;
        transition: 0.5s;
    }
    .navBar:hover {
        width: 330px;
        transition: 0.5s;
        padding: 50px 20px;
    }
    .navBar .top img {
        display: none;
    }
    .navBar:hover .top img {
        display: block;
    }
    .navBar .bottom ul li h3 {
        display: none;
    }
    .navBar:hover .bottom ul li h3 {
        display: block;
    }
    .navBar .bottom ul li div.right {
        display: none;
    }
    .navBar:hover .bottom ul li div.right {
        display: block;
    }
    .navBar .bottom ul li div.container .itemContainer {
        /* display: none; */
    }
    .navBar:hover .bottom ul li div.container .itemContainer {
        display: block;
    }
}

/* @media only screen and (max-width: 980px) {
    .navBar {
        width: 0%;
        padding: 0;
        position: absolute;
        height: 100%;
        z-index: 99999;
        transition: 1s;
        display: none;
    }
} */
